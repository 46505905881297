<template>
  <div>
    <section class="bet_group page_container">
      <div class="bet-item">
        <div class="box_one">
          <div class="statuses">
            <span
              :class="
                getStatusName(bet) === 'Cancelled'
                  ? 'cancelled'
                  : getStatusName(bet) === 'Won'
                  ? 'won'
                  : getStatusName(bet) === 'Lost'
                  ? 'lost'
                  : getStatusName(bet) === 'Pending'
                  ? 'pending'
                  : getStatusName(bet) === 'Voided'
                  ? 'voided'
                  : 'closed'
              "
            >
              {{ getStatusName(bet) }}
            </span>
          </div>
          <h3>Bet ID: {{ bet?.code }}</h3>
        </div>
        <div class="box_two">
          <p>{{ formatDateTime(bet?.created) }}</p>
        </div>
      </div>
    </section>
    <section class="page_container">
      <div class="overview_flex">
        <span>Total Odds::</span>
        <span>{{ bet?.total_odd }}{{ " " }}{{ fiatCurrency }}</span>
      </div>
      <div class="overview_flex">
        <span>Stake:</span>
        <span>{{ bet?.stake }} {{ fiatCurrency }}</span>
      </div>

      <div class="overview_flex">
        <span>Possible win:</span>
        <span>{{ bet?.possible_win }}{{ " " }}{{ fiatCurrency }}</span>
      </div>
      <div v-if="getStatusName(bet) === 'Won'">
        <div class="overview_flex">
          <span>Event Won:</span>
          <span>{{ bet?.possible_win }}{{ " " }}{{ fiatCurrency }}</span>
        </div>
        <div class="overview_flex">
          <span>Excise Tax:</span>
          <span>{{ bet?.tax_on_stake }}{{ " " }}{{ fiatCurrency }}</span>
        </div>
        <div class="overview_flex">
          <span>Withholding Tax:</span>
          <span>{{ bet?.tax_on_winning }}{{ " " }}{{ fiatCurrency }}</span>
        </div>
      </div>
    </section>
    <div class="bet_head">
      <div class="page_container">
        <span>Bets</span>
      </div>
    </div>
    <div
      v-for="(picked, index) in bet?.picked"
      :key="bet.id + index"
      class="bet_item"
    >
      <div class="bet_details page_container">
        <div class="box_one">
          <span>{{
            picked?.match_date && formatDateTime(picked?.match_date)
          }}</span>
          <p>
            {{ picked.match_name }}
          </p>
          <span> {{ picked.tournament }}</span>
          <span
            >Your pick: {{ picked?.market_name }} •
            {{ picked.outcome_name }}</span
          >
        </div>
        <div class="box_two">
          <p>{{ picked?.odd }}</p>
          <span
            :class="
              getStatusName(picked) === 'Won'
                ? 'bet_status won'
                : getStatusName(picked) === 'Pending'
                ? 'bet_status pending'
                : getStatusName(picked) === 'Lost'
                ? 'bet_status lost'
                : getStatusName(picked) === 'Void'
                ? 'bet_status void'
                : 'bet_status'
            "
          >
          </span>
        </div>
      </div>
    </div>
    <div class="page_container">
      <div class="rules">
        <span>Bets placed on {{ formatDateTime(bet.created) }}</span>
        <div>
          <p><span class="bet_status pending"></span> <span>Pending</span></p>
          <p><span class="bet_status won"></span> <span>Won</span></p>
          <p><span class="bet_status void"></span> <span>Void</span></p>
          <p>
            <span class="bet_status cancelled"></span> <span>Cancelled</span>
          </p>
          <p><span class="bet_status lost"></span> <span>Lost</span></p>
        </div>
      </div>
      <div class="action_groups">
        <div v-if="getStatusName(bet) === 'Cashout'">
          <ChopbetButton variant="Primary">Request Cashout </ChopbetButton>
        </div>
        <!-- <div>
          <ChopbetButton variant="outline">Share </ChopbetButton>
          <ChopbetButton variant="outline">Rebet </ChopbetButton>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import {
  formatBetStatusOrOutcome,
  formatDateTime,
} from "../../../utils/formatter";

export default {
  name: "MyBetDetails",

  mounted() {
    // console.log(this.bet, "--bet bet--");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  props: {
    bet: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  components: {
    ChopbetButton,
  },
  data: function () {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      isModalOpen: false,
    };
  },
  methods: {
    formatDateTime,
    formatBetStatusOrOutcome,
    getStatusName: function (picked) {
      if (parseInt(picked.status) === -1) {
        return "Cancelled";
      }
      if (parseInt(picked.won) === 1) {
        return "Won";
      }

      if (parseInt(picked.won) === 0) {
        return "Lost";
      }
      if (parseInt(picked.won) === -1) {
        return "Pending";
      }
      if (parseInt(picked.status) === 3) {
        return "Voided";
      }
      return "";
    },
  },
};
</script>

<style scoped>
p {
  color: #8c8787;
  font-family: "Satoshi";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
}

.bet_group {
  margin-top: 12px;
  & > span {
    /* color: #8c8787; */
    color: var(--sub-text-color);
    font-family: "Satoshi";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    margin-top: 8px;
  }
}
.bet-item {
  display: flex;
  padding: 12px;
  align-items: flex-end;
  justify-content: space-between;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  /* background: #f5f5f5; */
  background-color: var(--box-bg);
  /* outline: 1px solid red; */
  margin-bottom: 8px;
}

.box_one {
  width: 70%;
  h3 {
    /* color: #0e0d0d; */
    color: var(--text-color);
    font-family: "Satoshi";
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.12px;
    margin: 0;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  p {
  }
}

.box_two {
  width: 25%;
  p {
    /* color: #8c8787; */
    color: var(--sub-text-color);
    font-family: "Satoshi";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
  }
}

.statuses {
  display: flex;
  gap: 4px;
  span {
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #4db748;
    display: block;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.1px;
  }

  .lost {
    background: #cc1717;
  }

  .cancelled {
    background: #dbd3d3;
    color: #000;
  }
  .voided {
    background: #f6eded;
    color: #000;
  }
  .won {
    background: #4db748;
  }
  .pending {
    background: #e1ba2e;
    color: #0e0d0d;
  }
}

.bold {
  /* color: #0e0d0d; */
  color: var(--text-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
}

.overview_flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  span {
    /* color: #0e0d0d;s */
    color: var(--text-color);
  }
  p {
    /* color: #0e0d0d; */
    color: var(--text-color);
  }
}

.overview_flex span:nth-child(1) {
  /* color: var(--Light-Mode-Base-Base-900, #0e0d0d); */
  color: var(--text-color);

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
  margin-right: 4px;
}
.overview_flex span:nth-child(2) {
  /* color: var(--Light-Mode-Base-Base-600, #545151); */
  color: var(--sub-text-color);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
}

.no_padding {
  margin: 4px 0;
}

.bet_head {
  display: flex;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--box-bg);

  margin-top: 16px;
  span {
    /* color: #0e0d0d; */
    color: var(--text-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
  }
}

.bet_item {
  display: flex;
  padding: 8px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-bottom: 1px solid var(--border);
}

.bet_details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.bet_details .box_one {
  width: 70%;
  & span {
    /* color: #545151; */
    color: var(--sub-text-color);

    font-size: 12px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    letter-spacing: -0.12px;
    display: block;
  }
  & p {
    /* color: #0e0d0d; */
    color: var(--text-color);

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.12px;
    margin: 4px 0;
  }
}

.bet_details .box_two {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.bet_details .box_two span:nth-child(1) {
  /* color: #0e0d0d; */
  color: var(--text-color);

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
}
.bet_status {
  width: 12px;
  height: 12px;
  background-color: #d9d9d9;

  border-radius: 50px;
  margin-left: 8px;
}

.lost {
  background-color: #cc1717;
}

.void {
  background-color: #fff;
  outline: 1px solid #d9d9d9;
}

.cancelled {
  background: #dbd3d3;
  color: #000;
}
/* .voided {
  background: #f6eded;
  color: #000;
} */
.won {
  background: #4db748;
}
.pending {
  background: #e1ba2e;
  color: #0e0d0d;
}
.rules {
  text-align: center;
  margin: 16px 0;
  span {
    /* color: #545151; */
    color: var(--sub-text-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
  }
}

.rules div {
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  p {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.action_groups {
  /* padding-bottom: 3rem; */
  margin-top: 20px;
}

.action_groups div {
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
}

.action_groups div > button {
  width: 48%;
}

.action_groups > button:nth-child(1) {
  /* color: #fff; */
  color: var(--text-color);
}

.won {
  background: #4db748;
}
.pending {
  background: #e1ba2e;
  color: var(--Light-Mode-Base-Base-900, #0e0d0d);
}

.lost {
  background: #cc1717;
}
</style>
