var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"bet_group page_container"},[_c('div',{staticClass:"bet-item"},[_c('div',{staticClass:"box_one"},[_c('div',{staticClass:"statuses"},[_c('span',{class:_vm.getStatusName(_vm.bet) === 'Cancelled'
                ? 'cancelled'
                : _vm.getStatusName(_vm.bet) === 'Won'
                ? 'won'
                : _vm.getStatusName(_vm.bet) === 'Lost'
                ? 'lost'
                : _vm.getStatusName(_vm.bet) === 'Pending'
                ? 'pending'
                : _vm.getStatusName(_vm.bet) === 'Voided'
                ? 'voided'
                : 'closed'},[_vm._v(" "+_vm._s(_vm.getStatusName(_vm.bet))+" ")])]),_c('h3',[_vm._v("Bet ID: "+_vm._s(_vm.bet?.code))])]),_c('div',{staticClass:"box_two"},[_c('p',[_vm._v(_vm._s(_vm.formatDateTime(_vm.bet?.created)))])])])]),_c('section',{staticClass:"page_container"},[_c('div',{staticClass:"overview_flex"},[_c('span',[_vm._v("Total Odds::")]),_c('span',[_vm._v(_vm._s(_vm.bet?.total_odd)+_vm._s(" ")+_vm._s(_vm.fiatCurrency))])]),_c('div',{staticClass:"overview_flex"},[_c('span',[_vm._v("Stake:")]),_c('span',[_vm._v(_vm._s(_vm.bet?.stake)+" "+_vm._s(_vm.fiatCurrency))])]),_c('div',{staticClass:"overview_flex"},[_c('span',[_vm._v("Possible win:")]),_c('span',[_vm._v(_vm._s(_vm.bet?.possible_win)+_vm._s(" ")+_vm._s(_vm.fiatCurrency))])]),(_vm.getStatusName(_vm.bet) === 'Won')?_c('div',[_c('div',{staticClass:"overview_flex"},[_c('span',[_vm._v("Event Won:")]),_c('span',[_vm._v(_vm._s(_vm.bet?.possible_win)+_vm._s(" ")+_vm._s(_vm.fiatCurrency))])]),_c('div',{staticClass:"overview_flex"},[_c('span',[_vm._v("Excise Tax:")]),_c('span',[_vm._v(_vm._s(_vm.bet?.tax_on_stake)+_vm._s(" ")+_vm._s(_vm.fiatCurrency))])]),_c('div',{staticClass:"overview_flex"},[_c('span',[_vm._v("Withholding Tax:")]),_c('span',[_vm._v(_vm._s(_vm.bet?.tax_on_winning)+_vm._s(" ")+_vm._s(_vm.fiatCurrency))])])]):_vm._e()]),_vm._m(0),_vm._l((_vm.bet?.picked),function(picked,index){return _c('div',{key:_vm.bet.id + index,staticClass:"bet_item"},[_c('div',{staticClass:"bet_details page_container"},[_c('div',{staticClass:"box_one"},[_c('span',[_vm._v(_vm._s(picked?.match_date && _vm.formatDateTime(picked?.match_date)))]),_c('p',[_vm._v(" "+_vm._s(picked.match_name)+" ")]),_c('span',[_vm._v(" "+_vm._s(picked.tournament))]),_c('span',[_vm._v("Your pick: "+_vm._s(picked?.market_name)+" • "+_vm._s(picked.outcome_name))])]),_c('div',{staticClass:"box_two"},[_c('p',[_vm._v(_vm._s(picked?.odd))]),_c('span',{class:_vm.getStatusName(picked) === 'Won'
              ? 'bet_status won'
              : _vm.getStatusName(picked) === 'Pending'
              ? 'bet_status pending'
              : _vm.getStatusName(picked) === 'Lost'
              ? 'bet_status lost'
              : _vm.getStatusName(picked) === 'Void'
              ? 'bet_status void'
              : 'bet_status'})])])])}),_c('div',{staticClass:"page_container"},[_c('div',{staticClass:"rules"},[_c('span',[_vm._v("Bets placed on "+_vm._s(_vm.formatDateTime(_vm.bet.created)))]),_vm._m(1)]),_c('div',{staticClass:"action_groups"},[(_vm.getStatusName(_vm.bet) === 'Cashout')?_c('div',[_c('ChopbetButton',{attrs:{"variant":"Primary"}},[_vm._v("Request Cashout ")])],1):_vm._e()])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bet_head"},[_c('div',{staticClass:"page_container"},[_c('span',[_vm._v("Bets")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_c('span',{staticClass:"bet_status pending"}),_vm._v(" "),_c('span',[_vm._v("Pending")])]),_c('p',[_c('span',{staticClass:"bet_status won"}),_vm._v(" "),_c('span',[_vm._v("Won")])]),_c('p',[_c('span',{staticClass:"bet_status void"}),_vm._v(" "),_c('span',[_vm._v("Void")])]),_c('p',[_c('span',{staticClass:"bet_status cancelled"}),_vm._v(" "),_c('span',[_vm._v("Cancelled")])]),_c('p',[_c('span',{staticClass:"bet_status lost"}),_vm._v(" "),_c('span',[_vm._v("Lost")])])])
}]

export { render, staticRenderFns }