<template>
  <div>
    <div v-if="isDetails">
      <div class="page_container">
        <span @click="handleClearDetails">
          <BackComponent title="Bet Details" disable />
        </span>
      </div>
      <MyBetDetails :bet="currentDetails" @close="handleClearDetails" />
    </div>
    <div v-else class="page_container">
      <BackComponent :title="$t('myBets')" />
      <section class="bets-menu">
        <div
          class="tablinks"
          :class="isActive('sports')"
          @click="setActive('sports')"
        >
          {{ $t("sports") }}
        </div>
        <div
          class="tablinks"
          :class="isActive('casino')"
          @click="setActive('casino')"
        >
          {{ $t("casino") }}
        </div>
        <div
          class="tablinks"
          :class="isActive('jackpot')"
          @click="setActive('jackpot')"
        >
          {{ $t("jackpot") }}
        </div>
        <div
          class="tablinks"
          :class="isActive('virtuals')"
          @click="setActive('virtuals')"
        >
          {{ $t("virtuals") }}
        </div>
      </section>

      <div class="filter">
        <button @click="handleAction('status')">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.33334 1.33301V3.33301"
                stroke="var(--text-color)"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6667 1.33301V3.33301"
                stroke="var(--text-color)"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.33334 6.05957H13.6667"
                stroke="var(--text-color)"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 5.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V5.66634C2 3.66634 3 2.33301 5.33333 2.33301H10.6667C13 2.33301 14 3.66634 14 5.66634Z"
                stroke="var(--text-color)"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.4631 9.13314H10.4691"
                stroke="var(--text-color)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.4631 11.1331H10.4691"
                stroke="var(--text-color)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.997 9.13314H8.00299"
                stroke="var(--text-color)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.997 11.1331H8.00299"
                stroke="var(--text-color)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.52953 9.13314H5.53552"
                stroke="var(--text-color)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.52953 11.1331H5.53552"
                stroke="var(--text-color)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>{{ $t("betStatus") }}</span>
          </span>
          <span><CaretDown /></span>
        </button>
        <button @click="handleAction('time')">
          <span>{{ $t("timePeriod") }}</span> <span><CaretDown /></span>
        </button>
      </div>
      <div v-if="loadingGames">
        <MyBetsLoader :count="11" />
      </div>
      <section class="bet_group">
        <div v-if="betsData.length < 1 && !loadingGames">
          <NoData
            :title="$t('noAvailableBets')"
            :description="$t('youDontHaveBet')"
            :cta="$t('placeABet')"
            goTo="/"
          />
        </div>
        <div
          v-else
          v-for="(bet, idx) in betsData"
          :key="bet.id + idx"
          class="date-group"
        >
          <div @click="handleDetailsScreen(bet)">
            <div class="bet-item">
              <div class="box_one">
                <div class="statuses">
                  <span
                    :class="
                      getStatusName(bet) === 'Cancelled'
                        ? 'cancelled'
                        : getStatusName(bet) === 'Won'
                        ? 'won'
                        : getStatusName(bet) === 'Lost'
                        ? 'lost'
                        : getStatusName(bet) === 'Pending'
                        ? 'pending'
                        : getStatusName(bet) === 'Voided'
                        ? 'voided'
                        : 'closed'
                    "
                  >
                    {{ getStatusName(bet) }}
                  </span>
                </div>
                <h3>{{ $t("betId") }} {{ bet.code }}</h3>
                <p>
                  {{ $t("possiblePayout") }}:
                  <span class="bold"
                    >{{ bet.possible_win }} {{ fiatCurrency }}</span
                  >
                </p>
              </div>
              <div class="box_two">
                <p>{{ formatDateTime(bet.created) }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <div class="learn_more page_container">
        <div v-if="actionType === 'status'" class="filter_contents">
          <p>
            <span>Bet Status</span>
            <span @click="closeSlideUp"><CancelIcon /></span>
          </p>
          <div
            v-for="item in statusFilter"
            :key="item.id"
            class="item"
            @click="handleSelectedStatus(item.value)"
          >
            <span
              ><span>{{ item.name }}</span>
              <span v-if="selectedStatus === item.value">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99998 1.33301C4.32665 1.33301 1.33331 4.32634 1.33331 7.99967C1.33331 11.673 4.32665 14.6663 7.99998 14.6663C11.6733 14.6663 14.6666 11.673 14.6666 7.99967C14.6666 4.32634 11.6733 1.33301 7.99998 1.33301ZM11.1866 6.46634L7.40665 10.2463C7.31331 10.3397 7.18665 10.393 7.05331 10.393C6.91998 10.393 6.79331 10.3397 6.69998 10.2463L4.81331 8.35967C4.61998 8.16634 4.61998 7.84634 4.81331 7.65301C5.00665 7.45967 5.32665 7.45967 5.51998 7.65301L7.05331 9.18634L10.48 5.75967C10.6733 5.56634 10.9933 5.56634 11.1866 5.75967C11.38 5.95301 11.38 6.26634 11.1866 6.46634Z"
                    fill="#8F0DCC"
                  />
                </svg>
              </span>
            </span>
          </div>
        </div>
        <div v-if="actionType === 'time'" class="filter_contents">
          <p>
            <span>Time Period</span>
            <span @click="closeSlideUp"><CancelIcon /></span>
          </p>
          <div
            v-for="item in timeFilter"
            :key="item.id"
            class="item"
            @click="handleSelectedDate(item.value)"
          >
            <span
              ><span>{{ item.name }}</span>
              <span v-if="selectedDate === item.value"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99998 1.33301C4.32665 1.33301 1.33331 4.32634 1.33331 7.99967C1.33331 11.673 4.32665 14.6663 7.99998 14.6663C11.6733 14.6663 14.6666 11.673 14.6666 7.99967C14.6666 4.32634 11.6733 1.33301 7.99998 1.33301ZM11.1866 6.46634L7.40665 10.2463C7.31331 10.3397 7.18665 10.393 7.05331 10.393C6.91998 10.393 6.79331 10.3397 6.69998 10.2463L4.81331 8.35967C4.61998 8.16634 4.61998 7.84634 4.81331 7.65301C5.00665 7.45967 5.32665 7.45967 5.51998 7.65301L7.05331 9.18634L10.48 5.75967C10.6733 5.56634 10.9933 5.56634 11.1866 5.75967C11.38 5.95301 11.38 6.26634 11.1866 6.46634Z"
                    fill="#8F0DCC"
                  /></svg></span
            ></span>
          </div>
        </div>
      </div>
    </ChopbetSlideUp>
    <div v-if="betsData && betsData.length > 0">
      <scroll-loader
        v-show="betsData.length > 0"
        :loader-method="loadOnScroll"
        :loader-disable="is_busy || page >= last_page"
      >
        <div></div>
      </scroll-loader>
    </div>
  </div>
</template>

<script>
import axios from "@/services/api";
import bettingserve from "@/services/bettingserve";
import MyBetDetails from "./components/MyBetDetails.vue";
import BackComponent from "../../components/ui/BackComponent.vue";
import CaretDown from "../../components/icons/CaretDown.vue";
import {
  formatBetStatusOrOutcome,
  formatDate,
  formatDateTime,
} from "../../utils/formatter";
import NoData from "../../components/ui/NoData.vue";
import ChopbetSlideUp from "../../components/ui/ChopbetSlideUp.vue";
import CancelIcon from "../../components/icons/CancelIcon.vue";
import MyBetsLoader from "./components/MyBetsLoader.vue";

export default {
  name: "Bets",
  components: {
    BackComponent,
    MyBetDetails,
    MyBetsLoader,
    NoData,
    CaretDown,
    ChopbetSlideUp,
    CancelIcon,
  },
  data: function () {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      isDetails: false,
      page: 1,
      per_page: 10,
      isSlideUpOpen: false,
      loading: true,
      loadingGames: false,
      is_busy: false,
      bets: [],
      jackpotResults: [],
      free_bets: [],
      betsData: [],
      transactionHistory: [
        {
          title: "Withdrawal from wallet",
          id: "ID: 4567890",
          date: "12 Jul 2024",
          status: "success",
          amount: "- XOF 900",
          balance: "XOF 10,000",
        },
        {
          title: "Mega Jackpot Stake won",
          id: "ID: 4567890",
          date: "12 Jul 2024",
          status: "failed",
          amount: "- XOF 900",
          balance: "XOF 10,000",
        },
        {
          title: "Withdrawal from wallet",
          id: "ID: 4567890",
          date: "12 Jul 2024",
          status: "success",
          amount: "- XOF 900",
          balance: "XOF 10,000",
        },
        {
          title: "Withdrawal from wallet",
          id: "ID: 4567890",
          date: "12 Jul 2024",
          status: "success",
          amount: "- XOF 900",
          balance: "XOF 10,000",
        },
        {
          title: "Mega Jackpot Stake won",
          id: "ID: 4567890",
          date: "12 Jul 2024",
          status: "failed",
          amount: "- XOF 900",
          balance: "XOF 10,000",
        },
        {
          title: "Withdrawal from wallet",
          id: "ID: 4567890",
          date: "12 Jul 2024",
          status: "success",
          amount: "- XOF 900",
          balance: "XOF 10,000",
        },
      ],
      selectedStatus: "Show All",
      selectedDate: "Show All",
      currentDetails: {},
      active_menu: "sports",
      actionType: "",
      firstTimeLoading: true,
      statusFilter: [
        { id: 1, name: this.$t("showAll"), value: "Show All" },
        { id: 2, name: this.$t("won"), value: "Won" },
        { id: 8, name: this.$t("active"), value: "Active" },
        { id: 7, name: this.$t("cashoutGame"), value: "Cash Out" },
        { id: 4, name: this.$t("pending"), value: "Pending" },
        { id: 5, name: this.$t("closed"), value: "Closed" },
        { id: 6, name: this.$t("voided"), value: "Voided" },
        { id: 7, name: this.$t("cashback"), value: "Cashback" },
        { id: 3, name: this.$t("lost"), value: "Lost" },
      ],
      timeFilter: [
        { id: 1, name: this.$t("showAll"), value: "Show All" },
        { id: 2, name: this.$t("today"), value: "Today" },
        { id: 3, name: this.$t("thisWeek"), value: "This Week" },
        { id: 4, name: this.$t("thisMonth"), value: "This Month" },
      ],
    };
  },
  mounted() {
    // this.$store.dispatch("setCurrentPage", "bets");
    this.myBets();
  },
  watch: {
    active_menu(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.page = 1;
        this.betsData = [];
        this.last_page = 0;
        this.firstTimeLoading = true;
        if (this.isDetails) {
          return;
        }
        this.myBets();
      }
    },
  },
  methods: {
    loadOnScroll: function () {
      var vm = this;
      if (vm.betsData) {
        vm.myBets();
      }
    },
    formatDateTime,
    formatDate,
    formatBetStatusOrOutcome,
    closeSlideUp() {
      this.isSlideUpOpen = false;
    },
    handleSelectedStatus(status) {
      this.selectedStatus = status;
      this.closeSlideUp();
    },
    handleSelectedDate(date) {
      this.selectedDate = date;
      this.closeSlideUp();
    },
    handleAction(type) {
      this.actionType = type;
      this.isSlideUpOpen = true;
    },
    handleDetailsScreen(bet) {
      this.currentDetails = bet;
      this.isDetails = true;
    },
    handleClearDetails() {
      this.isDetails = false;
      this.currentDetails = {};
    },
    getDataTarget: function (id) {
      return "#collapseMybet" + id;
    },
    getArialControl: function (id) {
      return "collapseMybet" + id;
    },

    isActive: function (menu) {
      return this.active_menu === menu ? "active" : "";
    },
    setActive: function (menu) {
      this.active_menu = menu;
    },
    getStatusString(processing_status) {
      switch (processing_status) {
        case 0:
          return "pending";
        case 1:
          return "lost";
        case 2:
          return "won";
        case 3:
          return "voided";
        case -1:
          return "cancelled";
        case 5:
          return "cashout";
        default:
          return "error";
      }
    },
    getBetFilterStatus: function (bet) {
      switch (parseInt(bet.status)) {
        case 0:
          return "pending";

        case 1:
          return "lost";

        case 2:
          return "won";

        case 3:
          return "voided";

        case 4:
          return "cancelled";

        case 5:
          return "cashout";
      }
    },

    shareBet: function (code, odds) {
      var vm = this;
      var ob = {
        code: code,
        odds: odds,
      };

      // console.log(JSON.stringify(ob));
      vm.EventBus.$emit("share:bet", ob);
    },
    copyShareBetLink: function (code) {
      var link = "https://dev.chopbet.ci/share/" + code;
      this.copyToClipboard(link);
      this.copyText = "Copied";
    },
    getCounterClass: function (status) {
      if (parseInt(status) === parseInt(this.filter_status)) {
        return "bets-counter-selected";
      }

      return "bets-counter-unselected";
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },
    getMatchClass: function (picked) {
      if (parseInt(picked.status) === 0) {
        return "bd-callout-pending";
      }

      if (parseInt(picked.status) === 1) {
        return "bd-callout-pending";
      }

      if (parseInt(picked.status) === -1) {
        return "bd-callout-cancel";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
        return "bd-callout-won";
      }

      if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
        return "bd-callout-won";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
        return "bd-callout-lost";
      }
    },
    viewAll: function () {
      this.view_more = false;
    },
    setFilter: function (filter_status) {
      this.filter_status = filter_status;
    },
    getMyBetLabelClass: function (bet) {
      switch (parseInt(bet.filter_status)) {
        case 0:
          return "bet-text-pending";

        case 1:
          return "bet-text-lost";

        case 2:
          return "bet-text-won";

        case 3:
          return "bet-text-voided";

        case 4:
          return "bet-text-cancelled";

        case 5:
          return "bet-text-won";
      }
      return "";
    },
    getBetStatusName: function (bet) {
      switch (parseInt(bet.filter_status)) {
        case 0:
          return "pending";

        case 1:
          return "lost";

        case 2:
          return "won";

        case 3:
          return "voided";

        case 4:
          return "cancelled";

        case 5:
          return "cashout";
      }
      return "";
    },
    getBetsBorderClass: function (bet) {
      switch (parseInt(bet.filter_status)) {
        case 0:
          return "bets-item-pending";

        case 1:
          return "bets-item-lost";

        case 2:
          return "bets-item-won";

        case 3:
          return "bets-item-voided";

        case 4:
          return "bets-item-cancelled";

        case 5:
          return "bets-item-won";
      }

      return "";
    },
    toggleCollapse(jackpotId) {
      const elementId = "jackpot_" + jackpotId;
      const element = document.getElementById(elementId);
      if (element.classList.contains("show")) {
        element.classList.remove("show");
      } else {
        element.classList.add("show");
      }
    },

    myBets: function () {
      // this.reset();
      // console.log("my betssss.....");
      var vm = this;
      // var m = this.getProfile();
      // var p = m.a;
      // if (vm.is_busy) return;
      // console.log("my betssss.....01");

      if (
        !this.firstTimeLoading &&
        parseInt(vm.page) > parseInt(vm.last_page)
      ) {
        this.loadingGames = false;
        vm.is_busy = false;
        return;
      }
      // console.log("my betssss.....2");
      vm.is_busy = true;

      vm.EventBus.$emit("event:busy", true);
      this.loadingGames = true;

      var path = process.env.VUE_APP_BASE_BETTING_URL + "/bet";
      bettingserve
        .get(path, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          var betsData = response.data;
          this.loadingGames = false;
          vm.is_busy = false;
          if (betsData.length < 1) {
            this.betsData = [];
            return;
          }
          if (this.active_menu === "sports") {
            this.betsData = betsData;
            this.betsData = betsData;
          } else if (this.active_menu === "casino") {
            this.betsData = [];
          } else if (this.active_menu === "jackpot") {
            this.betsData = [];
          } else if (this.active_menu === "virtuals") {
            this.betsData = [];
          }

          // vm.bets = betsData;
          this.firstTimeLoading = false;
          // console.log("Response in mybets", betsData);
        })
        .catch((error) => {
          console.error("Error fetching bets:", error);
          this.loadingGames = false;

          if (error.response) {
            if ([401, 400, 428].includes(error.response.status)) {
              this.setError(`${this.$t("sessionExpired")}`);
              // this.$router.push({ name: "login", params: {} });
              this.logout();
              return;
            } else {
              this.setError(error.response.data.message);
            }
          } else {
            // vm.setError(
            //   "Failed",
            //   "An error occurred while fetching data. Please try again later."
            // );
          }
        });
    },
    myFreeBets: function () {
      this.reset();
      var vm = this;
      var m = this.getProfile();
      var p = m.a;
      this.loading = true;

      if (!p) {
        this.setError(this.$t("pleaseLoginProceed"));

        this.$router.push({ name: "login", params: {} });
        return;
      }
      var path = process.env.VUE_APP_BASE_FREEBET_URL + "/bet";
      // console.log("Fetching free bets from:", path);
      // console.log("Using API key:", vm.getAuth());

      bettingserve
        .get(path, {
          headers: {
            "api-key": vm.getAuth(), // Ensure the correct API key is used
          },
        })
        .then((response) => {
          console.log("Response in myfreebets", response);
          var betsData = response.data;
          vm.bets = betsData;
          this.loadingGames = false;
          vm.all =
            vm.pending =
            vm.lost =
            vm.won =
            vm.voided =
            vm.cancelled =
            vm.cashout =
              0;

          betsData.forEach((bet) => {
            // Update counters based on processing_status
            switch (bet.processing_status) {
              case 0:
                vm.pending++;
                break;
              case 1:
                vm.lost++;
                break;
              case 2:
                vm.won++;
                break;
              case 3:
                vm.voided++;
                break;
              case -1:
                vm.cancelled++;
                break;
              case 5:
                vm.cashout++;
                break;
            }

            // Additional properties for each bet
            bet.filter_status = bet.processing_status;
            bet.statusName = vm.getStatusString(bet.processing_status); // Using the provided method to get status name
            bet.betLabelClass = vm.getMyBetLabelClass(bet);
            bet.borderClass = vm.getBetsBorderClass(bet);

            // Separate bets into categories
            if (bet.bet_type === 1) {
              vm.free_bets.push(bet);
            } else {
              vm.sports.push(bet);
            }
          });

          // Total count of all bets
          vm.all = betsData.length;
        })
        .catch((error) => {
          console.error("Error fetching bets:", error);
          this.loadingGames = false;
          if (error.response) {
            // Log the full error response
            console.log("Error response:", error.response);

            if ([401, 400, 428].includes(error.response.status)) {
              this.setError(`${this.$t("sessionExpired")}`);
              return;
            } else {
              this.setError(error.response.data.message);
            }
          } else {
            // vm.setError(
            //   "Failed",
            //   "An error occurred while fetching data. Please try again later."
            // );
          }
        });
    },

    requestCashout: function (bet_id) {
      this.reset();
      var p = this.getProfile();
      if (!p) {
        this.setError(this.$t("pleaseLoginProceed"));

        this.$router.push({ name: "login", params: {} });
        return;
      }
      var vm = this;
      var path = process.env.VUE_APP_URL_REQUEST_CASHOUT.replace(
        "{profile_id}",
        p.d
      );
      var data = {
        bet_id: bet_id,
      };
      vm.cashoutLoading = "loading";
      axios
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          //var msg = res;
          var amount = res.data.message.amount;
          //var description = res.data.message.description;
          // console.log("GOT cashout amount here " + JSON.stringify(amount));

          //text: "Cashout this bet at {{ fiatCurrency}}. "+vm.formatCurrency(amount),

          vm.$swal
            .fire({
              title:
                `Cashout this bet at ${this.fiatCurrency}.` +
                vm.formatCurrency(amount),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Cashout",
            })
            .then((result) => {
              if (result.isConfirmed) {
                var path = process.env.VUE_APP_URL_ACCEPT_CASHOUT.replace(
                  "{profile_id}",
                  p.d
                );

                var data = {
                  bet_id: bet_id,
                };

                axios
                  .post(path, JSON.stringify(data), {
                    headers: {
                      "api-key": vm.getAuth(),
                    },
                  })
                  .then((res) => {
                    vm.cashoutLoading = "";

                    console.log("RESPONSE ==> " + JSON.stringify(res));

                    vm.$swal.fire(
                      "Submitted!",
                      "Your cashout is being processed, you will be notified shortly",
                      "success"
                    );
                  })
                  .catch((err) => {
                    vm.cashoutLoading = "";

                    vm.$swal.fire(
                      "Failed!",
                      "Error occurred processing your cashout request",
                      "error"
                    );

                    if (err.response) {
                      var message = "";

                      if (parseInt(err.response.status) === 428) {
                        message = err.response.data.message;

                        this.setError(message);

                        return;
                      }

                      if (parseInt(err.response.status) === 401) {
                        this.setError(`${this.$t("sessionExpired")}`);

                        // vm.logout();
                        return;
                      } else {
                        message = err.response.data.message;
                        this.setError(message);
                      }
                    } else if (err.request) {
                      this.setError("Please check your network");
                    } else {
                      //vm.setError("Failed", "Please check your network")
                      // console.log(JSON.stringify(err));
                    }
                  });
              } else {
                vm.cashoutLoading = "";
              }
            });
        })
        .catch((err) => {
          vm.cashoutLoading = "";

          console.log("RESPONSE HERE " + JSON.stringify(err));
          vm.$swal.fire(
            "Failed!",
            "Cashout for your bet is not available at the moment",
            "error"
          );
        });
    },

    selectedClass: function (filter_status) {
      return this.filter_status === filter_status
        ? "bets-tab-text btn-selected"
        : "bets-tab-text btn-normal";
    },
    getStatusName: function (picked) {
      if (parseInt(picked.status) === -1) {
        return "Cancelled";
      }
      if (parseInt(picked.won) === 1) {
        return "Won";
      }

      if (parseInt(picked.won) === 0) {
        return "Lost";
      }
      if (parseInt(picked.won) === -1) {
        return "Pending";
      }
      if (parseInt(picked.status) === 3) {
        return "Voided";
      }
      return "";
    },

    getBetLabelClass: function (picked) {
      if (parseInt(picked.status) === 0) {
        return "bet-text-pending";
      }

      if (parseInt(picked.status) === 1) {
        return "bet-text-pending";
      }

      if (parseInt(picked.status) === -1) {
        return "bet-text-cancel";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
        return "bet-text-won";
      }

      if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
        return "bet-text-lost";
      }

      if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
        return "bet-text-won";
      }

      return "";
    },
    openCity: function (cityName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");

      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      tablinks = document.getElementsByClassName("tablinks");

      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      if (document.getElementById(cityName)) {
        document.getElementById(cityName).style.display = "block";
      }

      this.className += " active";
    },
    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "unique-id-" + index + "-");
    },
  },
  computed: {
    profile: function () {
      return this.getProfile();
    },
    filteredBets: function () {
      var vm = this;

      if (vm.filter_status === -10) {
        return this.bets;
      }

      var bets = [];
      this.jQuery.each(this.bets, function (k, v) {
        if (parseInt(v.processing_status) === parseInt(vm.filter_status)) {
          bets.push(v);
        }
      });

      return bets;
    },
    borderClass: function () {
      switch (parseInt(this.filter_status)) {
        case 0:
          return "bets-item-pending";

        case 1:
          return "bets-item-lost";

        case 2:
          return "bets-item-won";

        case 3:
          return "bets-item-voided";

        case 4:
          return "bets-item-cancelled";
      }

      return "";
    },
    statusName: function () {
      switch (parseInt(this.filter_status)) {
        case 0:
          return "Pending";

        case 1:
          return "Lost";

        case 2:
          return "Won";

        case 3:
          return "Voided";

        case 4:
          return "Cancelled";
      }
      return "";
    },
    betLabelClass: function () {
      switch (parseInt(this.filter_status)) {
        case 0:
          return "bet-text-pending";

        case 1:
          return "bet-text-lost";

        case 2:
          return "bet-text-won";

        case 3:
          return "bet-text-voided";

        case 4:
          return "bet-text-cancelled";
      }

      return "";
    },
  },
};
</script>

<style scoped>
p {
  /* color: #8c8787; */
  color: var(--text-color);
  font-family: "Satoshi";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
}

.filter {
  display: flex;
  justify-content: space-between;

  padding: 4px 0px;
  border-bottom: 1px solid var(--fixture-border);
  margin-bottom: 8px;
  gap: 8px;
  gap: 8px;
}

.filter > button {
  width: 46%;
  border-radius: 4px;
  background: var(--box-bg);
  display: flex;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  span {
    color: var(--sub-text-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }
}

.date-group {
  cursor: pointer;
  & > span {
    color: #8c8787;
    font-family: "Satoshi";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.bet_group {
  & > span {
    color: #8c8787;
    font-family: "Satoshi";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    margin-top: 8px;
  }
}
.bet-item {
  display: flex;
  padding: 12px;
  align-items: flex-end;
  justify-content: space-between;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--box-bg);
  color: var(--text-color);
  margin-bottom: 8px;
}

.box_one {
  width: 70%;
  h3 {
    /* color: #0e0d0d; */
    color: var(--text-color);
    font-family: "Satoshi";
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.12px;
    margin: 0;
    margin-bottom: 4px;
    margin-top: 4px;
  }
}

.box_two {
  width: 25%;
  p {
    /* color: #8c8787; */
    color: var(--sub-text-color);
    font-family: "Satoshi";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
  }
}

.statuses {
  display: flex;
  gap: 4px;
  span {
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #4db748;
    display: block;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.1px;
  }

  .cancelled {
    background: #dbd3d3;
    color: #000;
  }
  .voided {
    background: #f6eded;
    color: #000;
  }
  .won {
    background: #4db748;
  }
  .pending {
    background: #e1ba2e;
    color: var(--Light-Mode-Base-Base-900, #0e0d0d);
  }

  .lost {
    background: #cc1717;
  }
}

.bold {
  /* color: #0e0d0d; */
  color: var(--text-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
}

.bets-menu {
  display: flex;
  padding: 4px 0px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid var(--fixture-border);
}
.tablinks {
  display: flex;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  color: var(--sub-text-color);
  gap: 4px;
  flex: 1 0 0;
}
.active {
  border-bottom: 1px solid #a31212;
  color: var(--text-color);
}
.loading_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 40vh;
}

.filter_contents p {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
  display: flex;
  padding: 16px 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border);
  svg {
    cursor: pointer;
  }
}

.item {
  display: flex;
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--border);
  cursor: pointer;

  & > span {
    /* color: var(--Light-Mode-Base-Base-900, #0e0d0d); */
    color: var(--text-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
